import { initializeApp } from 'firebase/app'
import { getFirestore, collection } from 'firebase/firestore'
// ... other firebase imports

export const firebaseApp = initializeApp({
    apiKey: "AIzaSyCz3ePnxe3Bl2bbjzLnRTimBun3gXeJ7jU",
    authDomain: "catjump-d95f2.firebaseapp.com",
    projectId: "catjump-d95f2",
    storageBucket: "catjump-d95f2.appspot.com",
    messagingSenderId: "971057726813",
    appId: "1:971057726813:web:fa7cd8facd5f61feee94b4",
    measurementId: "G-KFSPE0FXGW"
})

// used for the firestore refs
const db = getFirestore(firebaseApp)

// here we can export reusable database references
export const citiesRef = collection(db, 'cities')