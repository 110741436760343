import { createApp } from 'vue'
import { VueFire, VueFireAuth } from 'vuefire'
import App from './App.vue'
import router from './router'
// the file we created above with `database`, `firestore` and other exports
import { firebaseApp } from './firebase'
import VueGoogleMaps from '@fawmi/vue-google-maps'

const app = createApp(App)
app.use(VueFire, {
    // imported above but could also just be created here
    firebaseApp,
        modules: [
      // we will see other modules later on
      VueFireAuth(),
    ],
  })

  app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDbi_5698qVxGac7Nh11AXyHTHCpIyT2L4',
        language: 'de',
    },
})
  
app.use(router).mount('#app')
