<template>
  <div>
    <h1>Catjump is coming soon!</h1>
    <img alt="Vue logo" src="../assets/catjump.svg" width="150" height="150">

    <h1 v-if="city">
      {{ city.name }}
    </h1>

    <GoogleMaps></GoogleMaps>
  </div>
</template>

<script setup>
import { useFirebaseApp } from 'vuefire'
import { useDocument } from 'vuefire'
import { doc, getFirestore } from 'firebase/firestore'
import GoogleMaps from '@/components/GoogleMaps.vue';
const firebaseApp = useFirebaseApp()
const db = getFirestore(firebaseApp)

const city = useDocument(doc(db, 'cities', 'thun'))
</script>
