<template>
    <GMapMap :center="center" :zoom="15" :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: true,
        rotateControl: false,
        fullscreenControl: false,
        mapId: '73cf02888492c1d7'
    }" style="width: 100vw; height: 30rem">
        <GMapMarker :key="index" v-for="(m, index) in markers" :position="m.position" :clickable="true"
            @click="openMarker(m.id)">
            <GMapInfoWindow :closeclick="true" @closeclick="openMarker(null)" :opened="openedMarkerID === m.id">
                <div> 
                    <p>
                        <a target="_blank"
                        :href="m.link">{{ m.name }}</a>
                    </p>
                </div> 
            </GMapInfoWindow>
        </GMapMarker>

    </GMapMap>
</template>
<script>
export default {
    name: 'GoogleMaps',
    data() {
        return {
            openedMarkerID: null,
            center: { lat: 46.75489412719822, lng: 7.62964783124776 },
            markers: [
                {
                    id: 1,
                    name: "Jakobshübeli",
                    link: "https://www.interlaken.ch/erlebnisse/poi/jakobshuebeli",
                    position: {
                        lat: 46.75312100795393, lng: 7.638225007266416
                    },
                },
                {
                    id: 2,
                    name: "Grunderinseli",
                    position: {
                        lat: 46.73770467105943, lng: 7.632938120326859
                    },
                }

            ]
        }
    },
    methods: {
        openMarker(id) {
            this.openedMarkerID = id
        }
    }
}
</script>